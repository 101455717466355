import React from 'react';
import styled from 'styled-components';

import { Text, COLORS } from '@summer/ui-components/src';

/**
 * Header component for the account creation process showing title and step progress
 * @param {object} props
 * @param {string} props.title - The page title (e.g. "Create Account")
 * @param {number} props.currentStep - The current step number
 * @param {number} props.totalSteps - The total number of steps
 * @returns {JSX.Element}
 */
export const CreateAccountHeader = ({
  title = 'Create Account',
  currentStep = 1,
  totalSteps = 3,
}) => {
  return (
    <HeaderContainer>
      <ContentContainer>
        <Title>{title}</Title>
        <StepIndicator>
          <Text color="secondary" center>
            Step {currentStep} of {totalSteps}
          </Text>
        </StepIndicator>
      </ContentContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  padding-top: 24px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${COLORS.black};
  margin-bottom: 8px;
`;

const StepIndicator = styled.div`
  margin-top: 4px;
`;

export default CreateAccountHeader;
