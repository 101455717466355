import { push as redirect } from 'connected-react-router';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { COLORS } from '@summer/ui-components';

import OnboardingLayout from 'components/pages/onboarding/OnboardingLayout';
import VerifyEmailForm from 'components/pages/onboarding/VerifyEmailForm';
import {
  TERMS_OF_USE_LINK,
  PRIVACY_POLICY_LINK,
  CONSUMER_PRIVACY_NOTICE_LINK,
} from 'constants/externalLinks/supportLinks';
import { resetState } from 'redux/actions/resetAction.actions';
import { sendVerificationEmailCode } from 'redux/actions/user.actions';
import { getOnboardingCurrentStep } from 'redux/selectors/onboarding.selectors';
import { isLoading, getErrorCode } from 'redux/selectors/ui.selectors';
import { verifyEmailValidationSchema } from 'schemas/onboard';
import { scrollToError } from 'utils/formik';

const VerifyEmail = ({ resetState, sendVerificationEmailCode, isLoading }) => {
  useEffect(() => {
    // Preserve checkUser loading state from App to prevent infinite loading animation
    resetState({ preserve: 'ui.loading.checkUser' });
  }, [resetState]);

  const handleSubmit = values => {
    const { email, firstName, lastName } = values;
    sendVerificationEmailCode({ email, meta: { email, firstName, lastName } });
  };

  const renderForm = formik => {
    const disclaimer = (
      <>
        By clicking &ldquo;Continue&ldquo;, you agree that you have received,
        reviewed, and accept our{' '}
        <StyledLink
          href={TERMS_OF_USE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </StyledLink>
        ,{' '}
        <StyledLink
          href={PRIVACY_POLICY_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </StyledLink>
        , and{' '}
        <StyledLink
          href={CONSUMER_PRIVACY_NOTICE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Consumer Privacy Notice
        </StyledLink>
        .
      </>
    );

    return (
      <OnboardingLayout
        headerText="Create Account"
        stepText="Step 1 of 3"
        subHeaderText="Eligibility Verified!"
        subHeaderSecondaryText="Hooray! Now create your account"
        formComponent={VerifyEmailForm}
        formProps={{
          values: formik.values,
          errors: formik.errors,
          touched: formik.touched,
          handleChange: formik.handleChange,
          isSubmitting: formik.isSubmitting,
          handleBlur: formik.handleBlur,
          emailHelpMessage: '',
        }}
        disclaimer={disclaimer}
        onButtonClick={() => {
          formik.handleSubmit();
          scrollToError(formik.errors);
        }}
        isButtonDisabled={!formik.isValid}
        isLoading={isLoading}
      ></OnboardingLayout>
    );
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
      }}
      validationSchema={verifyEmailValidationSchema()}
      onSubmit={handleSubmit}
    >
      {renderForm}
    </Formik>
  );
};

const StyledLink = styled.a`
  color: ${COLORS.azure};
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'verifyEmail'),
  currentStep: getOnboardingCurrentStep(state, 'currentStep'),
  errorCode: getErrorCode(state, 'verifyEmail'),
});

export default withRouter(
  connect(mapStateToProps, {
    sendVerificationEmailCode,
    resetState,
    redirect,
  })(VerifyEmail),
);
