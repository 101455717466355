import {
  SET_NEXT_STEP,
  SET_PAGE_INFO,
  FINISH_WELCOME,
  SET_CURRENT_STEP,
  GO_BACK_ONBOARDING,
  SET_POST_ONBOARDING_DESTINATION,
  SEND_USER_OTP_NOTIFICATION_EMAIL,
  VERIFY_USER_OTP_CODE,
  VERIFY_USER_PRE_ACCOUNT_EMAIL_CODE,
  SEND_USER_PRE_ACCOUNT_NOTIFICATION_EMAIL,
} from 'constants/actionTypes';

export const setOnboardingStep = (currentStep, persistStep) => ({
  type: SET_NEXT_STEP,
  payload: {
    currentStep,
  },
  meta: {
    persistStep,
  },
});

export const persistOnboardingStep = currentStep => ({
  type: SET_CURRENT_STEP,
  payload: {
    currentStep,
  },
});

export const goBackOnboarding = page => ({
  type: GO_BACK_ONBOARDING,
  payload: {
    page,
  },
});

export const finishWelcome = () => ({
  type: FINISH_WELCOME,
});

export const setPageInfo = (stepCount, stepMessage) => ({
  type: SET_PAGE_INFO,
  payload: {
    stepCount,
    stepMessage,
  },
});

export const setPostOnboardingDestination = postOnboardingDestination => ({
  type: SET_POST_ONBOARDING_DESTINATION,
  payload: {
    postOnboardingDestination,
  },
});

/**
 * Creates an action to trigger sending OTP notification to user's email
 * @returns {Object} Redux action with type SEND_USER_OTP_NOTIFICATION
 */
export const sendUserOTPNotification = () => ({
  type: SEND_USER_OTP_NOTIFICATION_EMAIL,
});

/**
 * Creates an action to trigger sending Pre Account Email OTP notification to user's email
 * @param {string} email - The email address of the user
 * @returns {Object} Redux action with type SEND_USER_PRE_ACCOUNT_NOTIFICATION_EMAIL
 */
export const sendPreAccountVerificationNotification = email => ({
  type: SEND_USER_PRE_ACCOUNT_NOTIFICATION_EMAIL,
  payload: {
    email,
  },
});

/**
 * Creates an action to submit user's OTP verification code
 * @param {string} code - The 6-digit OTP code entered by the user
 * @returns {Object} Redux action with type VERIFY_USER_OTP_CODE and code payload
 */
export const submitUserOTPVerificationCode = code => ({
  type: VERIFY_USER_OTP_CODE,
  code,
});

/**
 * Creates an action to submit user's Pre Account Email OTP verification code
 * @param {string} code - The 6-digit OTP code entered by the user
 * @param {string} email - The email address of the user
 * @param {string} firstName - The first name of the user
 * @param {string} lastName - The last name of the user
 * @returns {Object} Redux action with type VERIFY_USER_PRE_ACCOUNT_EMAIL_CODE and code payload
 */
export const submitPreAccountEmailVerificationCode = ({
  code,
  email,
  firstName,
  lastName,
}) => ({
  type: VERIFY_USER_PRE_ACCOUNT_EMAIL_CODE,
  payload: {
    code,
    email,
    firstName,
    lastName,
  },
  meta: {
    firstName,
    lastName,
  },
});
