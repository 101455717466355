import {
  LOCATION_CHANGE,
  goBack,
  push as redirectTo,
} from 'connected-react-router';
import { findIndex } from 'lodash';

import { finish } from 'constants/onboardingSteps';
import { partnerConfig } from 'constants/partnerConfig';
import { setVerificationToken, verifyUser } from 'redux/actions/auth.actions';
import { setOnboardingStep } from 'redux/actions/onboard.actions';
import { resetState } from 'redux/actions/resetAction.actions';
import { showServerError } from 'redux/actions/ui.actions';
import handleSideEffects from 'redux/handleSideEffects';
import {
  getCurrentIdrFormStep,
  getCurrentIdrFormId,
  getIdrWizardSteps,
  getStepFromLocation,
} from 'redux/selectors/idr.selectors';
import { checkOnboardingStep, validOnboardingSteps } from 'utils/onboard';

// Routes that can't be accessed if the user is authenticated
const ROUTES_WITH_RESTRICTED_LOGIN = [
  '/login',
  '/onboard/create-account',
  /^\/create-account\/.*$/,
  '/onboard/adp-profile',
  '/forgot',
  '/reset',
  '/verify',
  '/create',
  /^\/oauth-callback\/.*$/,
  '/employer-signature',
  '/employer-signature-v2',
  '/employer-signature-v2/verification',
  '/logout/adp',
  '/saml/error',
  '/user/adp/verify',
  '/oauth/idp-initiated',
];
// Routes that can be accessed by any user regardless of authentication status.
const OPEN_ROUTES = [
  ...ROUTES_WITH_RESTRICTED_LOGIN,
  '/error',
  '/mfa/sms', // TODO: this should be restricted to logged-in users
  /^\/oauth-callback\/.*$/, // This one is in open routes to support agency transfer
];

/**
 * Routes that can be accessed by any authenticated user regardless of
 * verification status. Routes can be string or regular expressions. Regular
 * expressions should match the entire path to avoid matching incorrect routes.
 */
const PRE_VERIFICATION_ROUTES = [
  ...OPEN_ROUTES,
  '',
  '/',
  '/plan',
  '/repayment',
  '/pslf',
  '/settings',
  '/refinancing',
  /^\/user\/verify\/.*$/,
  /^\/forgiveness-finder\/?.*$/,
  /^\/guides\/?.*$/,
  /^\/onboard\/.*$/,
  '/create-account/oauth/email-verification/verify',
];

// Routes that a user cannot access if they are logged in
const UNAUTHENTICATED_ONLY_ROUTES = [
  '/login',
  '/onboard/create-account',
  /^\/create-account\/.*$/,
];

export const createPartnerOnboardUrlPattern = partnerNames =>
  new RegExp(`^/onboard/(${partnerNames.join('|')})`);

export const createShortPartnerOnboardUrlPattern = partnerNames =>
  new RegExp(`^/(${partnerNames.join('|')})`);

const partnerNames = Object.keys(partnerConfig);
const partnerOnboardUrlPattern = createPartnerOnboardUrlPattern(partnerNames);
const SHORT_PARTNER_ONBOARD_URL_PATTERN =
  createShortPartnerOnboardUrlPattern(partnerNames);

OPEN_ROUTES.push(partnerOnboardUrlPattern);
UNAUTHENTICATED_ONLY_ROUTES.push(partnerOnboardUrlPattern);

const isAllowlistRoute = (routes, path) =>
  findIndex(routes, route =>
    route instanceof RegExp ? route.test(path) : route === path,
  ) !== -1;

export const isPrivateRoute = path => !isAllowlistRoute(OPEN_ROUTES, path);

const navigationMiddleware = handleSideEffects({
  [LOCATION_CHANGE]: (dispatch, { payload }, state) => {
    [...document.getElementsByTagName('div')].forEach(item => {
      item.setAttribute('scrollTop', 0);
    });

    const { currentStep } = state.onboarding;
    const { pathname, search } = payload.location;
    const { id, isVerified } = state.currentUser;

    const userLogin = id !== null;

    const pathSplit = pathname.split('/') || '';
    const path = pathSplit.slice(0, pathSplit.length - 1);
    const userVerifyPath = path.join('/');
    const searchQuery = new URLSearchParams(search);
    const isAttemptingEmailVerification = userVerifyPath === '/user/verify';

    if (userLogin && ROUTES_WITH_RESTRICTED_LOGIN.includes(pathname)) {
      dispatch(
        redirectTo({
          pathname: '/',
        }),
      );

      return;
    }

    if (!userLogin) {
      if (isAttemptingEmailVerification) {
        if (searchQuery.get('isADP')) {
          const token = pathSplit[3];
          dispatch(verifyUser({ token }));
          dispatch(redirectTo({ pathname: '/user/adp/verify' }));

          return;
        }

        dispatch(
          redirectTo({
            pathname: '/login',
            search: `?return_to=${pathname}`,
            state: { token: pathSplit[3] },
          }),
        );
        dispatch(
          showServerError({
            label: 'login',
            error: {
              message:
                'Let&rsquo;s verify your email address. Login with the method you used to create an account.',
            },
          }),
        );
        return;
      }

      // Redirect user to login before email verification

      if (pathname === '/create') {
        dispatch(
          redirectTo({
            pathname: '/onboard/create-account',
          }),
        );
        return;
      }

      if (SHORT_PARTNER_ONBOARD_URL_PATTERN.test(pathname)) {
        dispatch(
          redirectTo({
            pathname: `/onboard${pathname}`,
            search,
          }),
        );
        return;
      }

      if (!isAllowlistRoute(OPEN_ROUTES, pathname)) {
        const locationTaste = {
          origin: pathname,
          token: pathSplit[3],
        };
        dispatch(
          redirectTo({
            pathname: '/login',
            state: locationTaste,
          }),
        );
        return;
      }

      return;
    }

    // When a user access the adp profile they are in the SAML flow
    // which may have invalidated a previous session
    if (pathname === '/onboard/adp-profile') {
      dispatch(resetState());
      return;
    }

    if (searchQuery.get('return_to')) {
      dispatch(
        redirectTo({
          pathname: searchQuery.get('return_to'),
        }),
      );
    }

    if (!isVerified && !isAllowlistRoute(PRE_VERIFICATION_ROUTES, pathname)) {
      /**
       * /loans is a special case because it is the default route for users who
       * are not recommended IDR. Redirect such users to IDR instead.
       */
      if (pathname === '/loans') {
        dispatch(
          redirectTo({
            pathname: '/repayment',
          }),
        );
      } else {
        dispatch(goBack());
      }
    }

    // Verify user if user is already logged in
    if (userVerifyPath === '/user/verify') {
      const token = pathSplit[3];
      dispatch(setVerificationToken(token));
    }

    if (currentStep !== finish) {
      if (!isVerified && currentStep !== '/onboard/verify') {
        // step to ensure unverified partner SSO users are redirected properly
        if (
          pathname !== '/onboard/creditkarma/oauth' &&
          !pathname.includes('creditkarma')
        ) {
          dispatch(setOnboardingStep('/onboard/verify'));
          dispatch(redirectTo('/onboard/verify'));
        }
      } else if (!checkOnboardingStep(currentStep, pathname)) {
        if (validOnboardingSteps.includes(currentStep)) {
          dispatch(redirectTo(currentStep));
        } else {
          // TODO remove this branch when removing feature flag
          dispatch(setOnboardingStep('/onboard/welcome'));
          dispatch(redirectTo('/onboard/welcome'));
        }
      }
    }

    // handle idr redirect logic
    if (pathSplit[1] === 'idr') {
      const stepFromLocation = getStepFromLocation(state);

      if (!getCurrentIdrFormId(state)) {
        dispatch(redirectTo('/repayment'));
      }

      if (getCurrentIdrFormStep(state) < stepFromLocation) {
        dispatch(
          redirectTo(getIdrWizardSteps(state)[getCurrentIdrFormStep(state)]),
        );
      }
    }

    // redirect logged in users away from login back to the dashboard
    const shouldRedirectToDashboard = () =>
      UNAUTHENTICATED_ONLY_ROUTES.some(route =>
        route instanceof RegExp ? route.test(pathname) : route === path,
      );

    if (shouldRedirectToDashboard()) {
      dispatch(
        redirectTo({
          pathname: '/',
        }),
      );
    }
  },
});

export default navigationMiddleware;
