import React from 'react';
import styled from 'styled-components';

import {
  AirBalloon,
  Button,
  FlexCenter,
  FlexColumn as RegularFlexColumn,
  Icon,
  Header,
  COLORS,
} from '@summer/ui-components';

import { TabletSize } from 'constants/styleguide';

const OnboardingLayout = ({
  headerText,
  subHeaderText,
  subHeaderSecondaryText,
  stepText,
  formComponent: FormComponent,
  formProps,
  disclaimer,
  buttonText = 'Continue',
  onButtonClick,
  isButtonDisabled,
  isLoading,
}) => {
  return (
    <FlexColumn>
      <HeaderSection>
        <HeaderContainer>
          <Header as="h4" center>
            {headerText}
          </Header>
          {stepText && (
            <Header as="h4" color="secondary" center>
              {stepText}
            </Header>
          )}
        </HeaderContainer>
        <AirBalloonIcon SvgComponent={AirBalloon} stroke={COLORS.azure} />
        <HeaderContainer>
          <Header center>{subHeaderText}</Header>
          {subHeaderSecondaryText && (
            <Header as="h3" color="secondary" center>
              {subHeaderSecondaryText}
            </Header>
          )}
        </HeaderContainer>
      </HeaderSection>
      <ContentSection>
        <FormComponent {...formProps} />
        {disclaimer && <SubTextContainer>{disclaimer}</SubTextContainer>}
        <ButtonContainer>
          <Button
            fullWidth={true}
            fakeDisabled={isButtonDisabled}
            isLoading={isLoading}
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      </ContentSection>
    </FlexColumn>
  );
};

const FlexColumn = styled(RegularFlexColumn)`
  flex: 0 1 auto;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const HeaderSection = styled(FlexCenter)`
  flex: 0 1 auto;
  width: 100%;
  max-width: 600px;
  padding: 0 24px;
  align-items: unset;
  margin-bottom: 16px;
  max-height: max-content;
  flex-direction: column;

  @media (max-width: ${TabletSize}) {
    align-items: center;
    flex-direction: column-reverse;
    padding: 0 16px;
  }
`;

const ContentSection = styled(FlexCenter)`
  flex: 0 1 auto;
  width: 100%;
  max-width: 600px;
  align-self: center;
  flex-direction: column;
  padding: 0;
  gap: 16px;
`;

const AirBalloonIcon = styled(Icon)`
  margin-bottom: 24px;
  width: 34px;
  height: 49px;
  align-self: center;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 24px;
`;

const SubTextContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 24px;
  text-align: left;

  @media (max-width: ${TabletSize}) {
    padding: 0 16px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 0 24px;

  @media (max-width: ${TabletSize}) {
    padding: 0 16px;
  }
`;

export default OnboardingLayout;
