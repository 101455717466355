import { push as redirectTo } from 'connected-react-router';
import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Strong, Text } from '@summer/ui-components/src';

import { TextContainer } from 'components/pages/default/wizard/layout/layout';
import { CreateAccountHeader } from 'components/pages/userIdentityVerification/CreateAccountHeader';
import { OTPPage } from 'components/pages/userIdentityVerification/OtpPage';
import { Link } from 'components/shared/typography';
import {
  sendPreAccountVerificationNotification,
  submitPreAccountEmailVerificationCode,
} from 'redux/actions/onboard.actions';
import { clearServerError } from 'redux/actions/ui.actions';
import {
  sendPreAccountNotificationLabel,
  submitPreAccountVerificationCodeLabel,
} from 'redux/middleware/verifyUserOtp.middleware';
import { getErrorCode, getErrorMessage } from 'redux/selectors/ui.selectors';
import { addWbrToLongEmail } from 'utils/email';

const GoBackComponent = ({ email, goToPreviousFormHandler }) => {
  return (
    <TextContainer>
      <Text large center paragraph>
        Enter code sent to <Strong>{addWbrToLongEmail(email)}</Strong>
        . <br /> Wrong email?{' '}
        <Link onClick={goToPreviousFormHandler}>Change</Link>
      </Text>
    </TextContainer>
  );
};

const PreAccountEmailOtp = ({
  submitVerificationCodeError,
  sendOTPNotificationErrorCode,
}) => {
  const dispatch = useDispatch();

  const title = 'Verify your email address to continue';
  const createAccountFormRoute = '/create-account';

  const location = useLocation();
  const { email, firstName, lastName } = location.state;

  const handleSubmitUserOTPVerificationCode = code => {
    dispatch(
      submitPreAccountEmailVerificationCode({
        code,
        email,
        firstName,
        lastName,
      }),
    );
    dispatch(clearServerError(submitPreAccountVerificationCodeLabel));
  };

  const handleSendUserOtpNotification = () => {
    dispatch(sendPreAccountVerificationNotification(email));
    dispatch(clearServerError(sendPreAccountNotificationLabel));
  };

  const goToPreviousFormHandler = () => {
    dispatch(redirectTo(createAccountFormRoute));
  };

  return (
    <>
      <CreateAccountHeader
        title="Create Account"
        currentStep={2}
        totalSteps={3}
      />
      <OTPPage
        pageTitle={title}
        email={email}
        onSubmitCode={handleSubmitUserOTPVerificationCode}
        onSendCode={handleSendUserOtpNotification}
        sendOTPNotificationErrorCode={sendOTPNotificationErrorCode}
        error={submitVerificationCodeError}
        subTitleComponent={
          <GoBackComponent
            email={email}
            goToPreviousFormHandler={goToPreviousFormHandler}
          />
        }
      />
    </>
  );
};

const mapStateToProps = state => ({
  submitVerificationCodeError: getErrorMessage(
    state,
    submitPreAccountVerificationCodeLabel,
  ),
  sendOTPNotificationErrorCode: getErrorCode(
    state,
    sendPreAccountNotificationLabel,
  ),
});

export default connect(mapStateToProps)(PreAccountEmailOtp);
