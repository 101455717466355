import { push as redirectTo } from 'connected-react-router';

import {
  NETWORK_FAILURE,
  NETWORK_SUCCESS,
  SEND_USER_OTP_NOTIFICATION_EMAIL,
  SEND_USER_PRE_ACCOUNT_NOTIFICATION_EMAIL,
  VERIFY_USER_OTP_CODE,
  VERIFY_USER_PRE_ACCOUNT_EMAIL_CODE,
} from 'constants/actionTypes';
import { welcome } from 'constants/onboardingSteps';
import { apiRequest } from 'redux/actions/api.actions';
import { oauthSignup } from 'redux/actions/auth.actions';
import {
  persistOnboardingStep,
  setOnboardingStep,
} from 'redux/actions/onboard.actions';
import { clearServerError } from 'redux/actions/ui.actions';
import { hydrateUser } from 'redux/actions/user.actions';
import handleSideEffects from 'redux/handleSideEffects';

export const submitUserOtpVerificationCodeLabel =
  'submitUserOtpVerificationCode';
export const submitPreAccountVerificationCodeLabel =
  'submitPreAccountVerificationCode';
export const sendUserOtpNotificationLabel = 'sendUserOtpNotification';
export const sendPreAccountNotificationLabel = 'sendUserPreAccountNotification';

const verifyUserOtpMiddleware = handleSideEffects({
  [SEND_USER_OTP_NOTIFICATION_EMAIL]: dispatch => {
    dispatch(
      apiRequest({
        url: '/v2/email/verification',
        method: 'POST',
        fromAction: SEND_USER_OTP_NOTIFICATION_EMAIL,
        label: sendUserOtpNotificationLabel,
      }),
    );
  },

  [VERIFY_USER_OTP_CODE]: (dispatch, { code }) => {
    const endpoint = '/v2/user/verify';
    const method = 'POST';

    dispatch(
      apiRequest({
        url: endpoint,
        method,
        fromAction: VERIFY_USER_OTP_CODE,
        label: submitUserOtpVerificationCodeLabel,
        body: { code },
      }),
    );
  },

  [SEND_USER_PRE_ACCOUNT_NOTIFICATION_EMAIL]: (dispatch, { payload }) => {
    const endpoint = '/v1/create-account/oauth/email-verification/send';

    dispatch(
      apiRequest({
        url: endpoint,
        method: 'POST',
        fromAction: SEND_USER_PRE_ACCOUNT_NOTIFICATION_EMAIL,
        label: sendPreAccountNotificationLabel,
        body: payload,
      }),
    );
  },

  [VERIFY_USER_PRE_ACCOUNT_EMAIL_CODE]: (dispatch, { payload, meta }) => {
    const endpoint = '/v1/create-account/oauth/email-verification/verify';
    const method = 'POST';

    dispatch(
      apiRequest({
        url: endpoint,
        method,
        fromAction: VERIFY_USER_PRE_ACCOUNT_EMAIL_CODE,
        label: submitPreAccountVerificationCodeLabel,
        body: { code: payload.code },
        meta,
      }),
    );
  },

  [NETWORK_FAILURE]: (dispatch, { meta, payload }) => {
    if (meta.fromAction === VERIFY_USER_PRE_ACCOUNT_EMAIL_CODE) {
      const unauthorized = payload.error.status === 401;
      if (unauthorized) {
        dispatch(clearServerError(submitPreAccountVerificationCodeLabel));
        dispatch(redirectTo('/login'));
      }
    }
  },

  [NETWORK_SUCCESS]: (dispatch, { meta }) => {
    const bootstrapUserActions = [VERIFY_USER_OTP_CODE];

    if (meta.fromAction === VERIFY_USER_PRE_ACCOUNT_EMAIL_CODE) {
      dispatch(
        oauthSignup({ firstName: meta.firstName, lastName: meta.lastName }),
      );
    }

    if (bootstrapUserActions.includes(meta.fromAction)) {
      dispatch(setOnboardingStep(welcome));
      dispatch(persistOnboardingStep(welcome));
      dispatch(hydrateUser());
      dispatch(redirectTo(welcome));
    }
  },
});

export default verifyUserOtpMiddleware;
