import React, { useState } from 'react';
import styled from 'styled-components';

import { Alert, Button, OTPInput, Text } from '@summer/ui-components/src';

import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { usePlatform } from 'hooks/platform';

import { VerificationPageHeader } from '../onboarding/shared/VerificationPageHeader';

/**
 * OTPPage component
 * @param {object} props
 * @param {string} props.pageTitle
 * @param {string} props.error
 * @param {string} props.sendOTPNotificationErrorCode
 * @param {function} props.onSubmitCode
 * @param {function} props.onSendCode
 * @param {function} props.secondsBetweenCodes
 * @param {JSX.Element} props.subTitleComponent
 * @returns {JSX.Element}
 */
export const OTPPage = ({
  pageTitle,
  error: errorMessage,
  onSubmitCode,
  onSendCode,
  sendOTPNotificationErrorCode,
  subTitleComponent = null,
}) => {
  const OTP_INPUT_TYPE = 'number';

  const { isMobile } = usePlatform();

  const [otpVerificationSent, setOtpVerificationSent] = useState(false);
  const [otp, setOtp] = useState('');

  /**
   * Submits user OTP verification
   * @returns {void}
   */
  const submitVerificationCode = () => {
    setOtpVerificationSent(false);
    onSubmitCode(otp);
  };

  /**
   * Sends OTP notification to user
   * @returns {void}
   */
  const sendOtpNotification = () => {
    onSendCode();
    setOtpVerificationSent(true);
  };

  /**
   * Formats error message
   * @param {string} errorMessage
   * @param {string} sendOTPNotificationErrorCode
   * @returns {string}
   */
  const formatError = (errorMessage, sendOTPNotificationErrorCode) => {
    const knownSubmitOTPNotificationErrorCodes = new Map([
      [
        '429',
        'Too many requests, please wait a few minutes and then try again',
      ],
    ]);

    if (
      sendOTPNotificationErrorCode &&
      knownSubmitOTPNotificationErrorCodes.has(sendOTPNotificationErrorCode)
    ) {
      return knownSubmitOTPNotificationErrorCodes.get(
        sendOTPNotificationErrorCode.toString(),
      );
    }

    if (errorMessage?.length > 0) {
      return errorMessage;
    }

    return 'Something went wrong. Please try again or contact Summer support if the issue persists.';
  };

  const showError = errorMessage || sendOTPNotificationErrorCode;

  return (
    <PageContainer>
      <ContentWrapper>
        <VerificationPageHeader verificationPageText={pageTitle} />
        {subTitleComponent}
        <OTPInput
          numInputs={6}
          value={otp}
          onChange={setOtp}
          width={isMobile ? 40 : 55}
          height={isMobile ? 40 : 55}
          inputType={OTP_INPUT_TYPE}
        />
        <Button
          type="submit"
          fullWidth
          onClick={submitVerificationCode}
          disabled={otp.length !== 6}
        >
          Verify
        </Button>
        <ResendLink color="secondary">
          <Link inheritColor onClick={sendOtpNotification}>
            Resend Code
          </Link>
        </ResendLink>
        {otpVerificationSent && (
          <Alert
            theme="success"
            testId="new-code-alert-text"
            dismissable={false}
          >
            A new email was sent with a verification code. Please enter the new
            code to verify your account.
          </Alert>
        )}
        {showError && (
          <Alert theme="warning" testId="invalid-code-alert-text">
            {formatError(errorMessage, sendOTPNotificationErrorCode)}
          </Alert>
        )}
        <Text small paragraph align="center">
          Having trouble? Contact us at{' '}
          <Link href={`mailto:${supportAddress}`} inheritColor underline>
            {supportAddress}
          </Link>{' '}
          and we&rsquo;ll get back to you.
        </Text>
      </ContentWrapper>
    </PageContainer>
  );
};

const ResendLink = styled(Text)`
  margin-bottom: 24px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
