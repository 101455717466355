import React from 'react';

import { Strong, Text } from '@summer/ui-components/src';

import { addWbrToLongEmail } from 'utils/email';

import { TextContainer } from '../default/wizard/layout/layout';

export const OtpStandardEmailSubtitle = ({ email }) => {
  return (
    <TextContainer>
      <Text large center paragraph>
        We have sent a code to <Strong>{addWbrToLongEmail(email)}</Strong>
        . <br /> Please enter the code provided to verify your email
      </Text>
    </TextContainer>
  );
};
