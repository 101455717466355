import { push as redirectTo } from 'connected-react-router';
import { isEqual, keys, pick, unset } from 'lodash';

import {
  FETCH_CURRENT_STEP,
  FILL_LOAN_INFO,
  FILL_SURVEY,
  FORGOT_PASSWORD,
  NETWORK_SUCCESS,
  NETWORK_FAILURE,
  RESET_PASSWORD,
  SEND_ACCOUNT_VERIFICATION_EMAIL,
  SET_CURRENT_STEP,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  VERIFY_USER_EMAIL,
  CHECK_EMAIL_IS_VERIFIED,
  SET_UI_SETTING,
  UPDATE_IS_CURRENTLY_ENROLLED_IN_IDR,
  HYDRATE_USER,
  FETCH_ELIGIBILITY_BENEFITS,
  SEND_EMAIL_VERIFICATION_CODE,
  SEND_NEW_EMAIL_VERIFICATION_EMAIL,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import { setUser } from 'redux/actions/auth.actions';
import { idrNetworkSuccess } from 'redux/actions/idr.actions';
import {
  fetchEligibilityBenefits,
  setEligibilityBenefits,
  setIsCurrentlyEnrolledInIdr,
  setLogout,
  setUserEmail,
  verifyUserSuccess,
} from 'redux/actions/user.actions';
import handleSideEffects from 'redux/handleSideEffects';

const userMiddleware = handleSideEffects({
  [VERIFY_USER_EMAIL]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/user/verify',
        method: 'POST',
        body: { ...payload },
        fromAction: VERIFY_USER_EMAIL,
        label: 'verify-user',
      }),
    );
  },

  [CHECK_EMAIL_IS_VERIFIED]: dispatch => {
    dispatch(
      apiRequest({
        url: '/user/verify',
        method: 'GET',
        fromAction: CHECK_EMAIL_IS_VERIFIED,
        label: 'checkEmailIsVerified',
      }),
    );
  },

  [SEND_EMAIL_VERIFICATION_CODE]: (dispatch, { payload, meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/create-account/oauth/email-verification/send',
        method: 'POST',
        body: { ...payload },
        fromAction: SEND_EMAIL_VERIFICATION_CODE,
        label: 'sendEmailVerificationCode',
        meta,
      }),
    );
  },

  [SEND_ACCOUNT_VERIFICATION_EMAIL]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/email/verification',
        method: 'POST',
        body: { ...payload },
        fromAction: SEND_ACCOUNT_VERIFICATION_EMAIL,
        label: 'sendAccountVerificationEmail',
      }),
    );
  },

  [SEND_NEW_EMAIL_VERIFICATION_EMAIL]: dispatch => {
    dispatch(
      apiRequest({
        url: '/user/email/verification',
        method: 'POST',
        fromAction: SEND_NEW_EMAIL_VERIFICATION_EMAIL,
        label: 'sendNewEmailVerificationEmail',
      }),
    );
  },

  [FORGOT_PASSWORD]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/forgot',
        method: 'POST',
        body: { ...payload },
        fromAction: FORGOT_PASSWORD,
        label: 'forgot',
      }),
    );
  },

  [RESET_PASSWORD]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/reset',
        method: 'POST',
        body: { ...payload },
        fromAction: RESET_PASSWORD,
        label: 'reset',
      }),
    );
  },

  [UPDATE_EMAIL]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/user/email',
        method: 'PATCH',
        body: { ...payload },
        fromAction: UPDATE_EMAIL,
        label: 'updateEmail',
      }),
    );
  },

  [UPDATE_PASSWORD]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/user/password',
        method: 'PATCH',
        body: { ...payload },
        fromAction: UPDATE_PASSWORD,
        label: 'updatePassword',
      }),
    );
  },

  [FETCH_CURRENT_STEP]: dispatch => {
    dispatch(
      apiRequest({
        url: '/step',
        method: 'GET',
        fromAction: FETCH_CURRENT_STEP,
        label: 'fetchCurrentStep',
      }),
    );
  },

  [SET_CURRENT_STEP]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/step',
        method: 'POST',
        body: { ...payload },
        fromAction: SET_CURRENT_STEP,
        label: 'setCurrentStep',
      }),
    );
  },

  [FILL_SURVEY]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/user/survey',
        method: 'POST',
        body: { ...payload },
        fromAction: FILL_SURVEY,
        label: 'survey',
      }),
    );
  },

  [FILL_LOAN_INFO]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/user/survey',
        method: 'POST',
        body: { ...payload },
        fromAction: FILL_LOAN_INFO,
        label: 'survey',
      }),
    );
  },

  [SET_UI_SETTING]: (dispatch, { payload }) => {
    dispatch(
      apiRequest({
        url: '/user/ui-setting',
        method: 'PUT',
        body: { ...payload },
        fromAction: SET_UI_SETTING,
        label: 'setUiSetting',
      }),
    );
  },

  [FETCH_ELIGIBILITY_BENEFITS]: dispatch => {
    dispatch(
      apiRequest({
        url: '/v1/user/eligibility',
        method: 'GET',
        fromAction: FETCH_ELIGIBILITY_BENEFITS,
        label: 'fetchEligibilityBenefits',
      }),
    );
  },

  // Used in IDR questionnaire. Kept separate from FILL_LOAN_INFO in onboarding to avoid side effects in middleware associated with that action
  [UPDATE_IS_CURRENTLY_ENROLLED_IN_IDR]: (dispatch, { payload, meta }) => {
    dispatch(
      apiRequest({
        url: '/user/survey',
        method: 'POST',
        body: { ...payload },
        fromAction: UPDATE_IS_CURRENTLY_ENROLLED_IN_IDR,
        meta,
        label: 'updateIsCurrentlyEnrolledInIdr',
      }),
    );
  },

  [HYDRATE_USER]: (dispatch, { meta }) => {
    dispatch(
      apiRequest({
        url: '/user',
        method: 'GET',
        meta,
        fromAction: HYDRATE_USER,
        label: 'hydrateUser',
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }, state) => {
    if (meta.fromAction === VERIFY_USER_EMAIL) {
      dispatch(verifyUserSuccess());
    }

    if (meta.fromAction === CHECK_EMAIL_IS_VERIFIED) {
      if (payload.response.user.isVerified === true) {
        dispatch(verifyUserSuccess());
      }
    }

    if (meta.fromAction === FORGOT_PASSWORD) {
      dispatch(setLogout());
    }

    if (meta.fromAction === UPDATE_EMAIL) {
      dispatch(setUserEmail(meta.request.body.email));
    }

    if (meta.fromAction === UPDATE_IS_CURRENTLY_ENROLLED_IN_IDR) {
      dispatch(setIsCurrentlyEnrolledInIdr(meta.request.body));

      dispatch(idrNetworkSuccess({ meta }));
    }

    if (meta.fromAction === HYDRATE_USER) {
      if (
        !isEqual(
          state.currentUser,
          pick(payload.response.user, keys(state.currentUser)),
        )
      ) {
        dispatch(setUser(payload.response, meta), unset(meta, 'fromAction'));
      }
      dispatch(fetchEligibilityBenefits());
    }

    if (meta.fromAction === FETCH_ELIGIBILITY_BENEFITS) {
      const {
        employeeId,
        educationalAssistance,
        studentLoanContribution,
        summerSave,
        retirementMatch,
      } = payload.response;

      dispatch(
        setEligibilityBenefits({
          employeeId,
          educationalAssistance,
          studentLoanContribution,
          summerSave,
          retirementMatch,
        }),
      );
    }

    if (meta.fromAction === SEND_EMAIL_VERIFICATION_CODE) {
      const routerState = {
        firstName: meta.firstName,
        lastName: meta.lastName,
        email: meta.email,
      };

      dispatch(
        redirectTo(
          '/create-account/oauth/email-verification/verify',
          routerState,
        ),
      );
    }
  },

  [NETWORK_FAILURE]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === SEND_EMAIL_VERIFICATION_CODE) {
      if (payload.response.status === 409) {
        dispatch(redirectTo('/login'));
      }
      if (payload.response.status >= 500) {
        dispatch(redirectTo('/error'));
      }
    }
  },
});

export default userMiddleware;
