import { isNil } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { TextInput, Text, COLORS } from '@summer/ui-components';

import { Label } from 'components/pages/onboarding/shared';
import GlobalError from 'components/shared/GlobalError';
import { TabletSize } from 'constants/styleguide';
import { getErrorMessage } from 'redux/selectors/ui.selectors';

const EmailInputWithWarning = ({ value, error, ...props }) => {
  const showWarning = !error && value?.toLowerCase().endsWith('.gov');
  const warningMessage =
    'We recommend not using a work email. If you lose access after changing jobs, you might have trouble recovering your account.';

  return (
    <EmailInputContainer>
      <TextInput {...props} value={value} error={error} />
      {showWarning && <WarningText>{warningMessage}</WarningText>}
    </EmailInputContainer>
  );
};

const VerifyEmailForm = ({
  errors,
  touched,
  values,
  isSubmitting,
  handleChange,
  handleBlur,
  emailHelpMessage,
  globalErrorMessage,
}) => {
  return (
    <Form>
      <SplitRow>
        <InputContainer>
          <TextInput
            label="First Name"
            name="firstName"
            error={errors.firstName}
            touched={touched.firstName}
            isSubmitting={isSubmitting}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="given-name"
          />
        </InputContainer>
        <InputContainer>
          <TextInput
            label="Last Name"
            name="lastName"
            error={errors.lastName}
            touched={touched.lastName}
            isSubmitting={isSubmitting}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="family-name"
          />
        </InputContainer>
      </SplitRow>
      <EmailSection>
        <Label>Set your email</Label>
        <SubTextContainer>
          <Text paragraph>
            This email will be used for login, communications from Summer about
            your benefits, and to recover your account if you lose benefits
            through your employer.
          </Text>
        </SubTextContainer>
        <EmailInputWithWarning
          label="Email"
          name="email"
          error={errors.email}
          value={values.email}
          touched={touched.email}
          isSubmitting={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          helpMessage={emailHelpMessage}
          inputMode="email"
          autoComplete="email"
        />
      </EmailSection>
      {!isNil(globalErrorMessage) ? (
        <ErrorBox>
          <GlobalError label="verifyEmail" />
        </ErrorBox>
      ) : null}
    </Form>
  );
};

const InputContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 16px;
`;

const ErrorBox = styled.div`
  min-height: 50px;
  width: 100%;
  max-width: 600px;
`;

const EmailSection = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 16px;
`;

const SplitRow = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
  }

  & > * {
    :first-child {
      margin-right: 16px;
    }

    @media (max-width: ${TabletSize}) {
      :first-child {
        margin-right: 0;
      }
    }
  }
`;

const SubTextContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 16px;
`;

const EmailInputContainer = styled.div`
  width: 100%;
  max-width: 600px;
`;

const WarningText = styled(Text)`
  color: ${COLORS.orange};
  margin-top: 0px;
  font-size: 14px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: ${TabletSize}) {
    padding: 0 16px;
  }
`;

const mapStateToProps = state => ({
  globalErrorMessage: getErrorMessage(state, 'verifyEmail'),
});

export default withRouter(connect(mapStateToProps)(VerifyEmailForm));
